%button-reset {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;

  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}
