@import 'styles/scss/_base';

.root {
  position: relative;
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $white;

  @media screen and (min-width: $breakpoint_2xs) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: $breakpoint_m) {
    margin-bottom: 32px;
  }
}

.icon {
  position: absolute;
  width: 32px;
  height: 32px;
  z-index: 10;
  cursor: pointer;
}

.input {
  padding: 0 0 0 47px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: transparent;

  @media screen and (min-width: $breakpoint_2xs) {
    font-size: 16px;
  }

  @include d-t6-regular($white);

  // 30?
  @include line_height(26px);

  &::placeholder {
    color: $blue_grey;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.button {
  visibility: hidden;
}
