@import 'styles/scss/_base';

.titleWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  color: white;
  margin-right: 12px;
  text-decoration: none;
}

.logo {
  margin-right: 8px;
  flex-shrink: 0;
  width: 36px;
  height: 24px;
}

.title {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: inherit;

  @include font_size(13px);
  @include line_height(18px);
}
