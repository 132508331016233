@import 'styles/scss/_base';

.root {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
  height: var(--navbar-height);
  width: 100%;
  background-color: $black_pearl;

  @keyframes slide-in {
    from {
      top: calc(var(--navbar-height) * -1);
    }
    to {
      top: 0;
    }
  }

  @media screen and (min-width: $breakpoint_2xs) {
    padding: 0px 8px;
  }

  @media screen and (min-width: $breakpoint_m) {
    padding: 0px 24px;
  }
}
