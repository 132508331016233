@import 'styles/scss/_base';

.root {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 30px 15px;
  color: $black_pearl;

  a {
    text-decoration: none;
  }

  @media screen and (min-width: $breakpoint_m) {
    padding: 60px 60px 30px;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    padding: 60px 120px 30px;
  }
}

.widgets {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 30px;
    min-width: 100px;
  }

  @media screen and (min-width: $breakpoint_m) {
    margin: 0 -30px 60px;
    flex-direction: row;

    > div {
      margin: 0 30px;
      margin-bottom: 0px;
      flex-grow: 1;

      &:first-child {
        flex-shrink: 1;

        max-width: 390px;
      }
    }
  }

  @media screen and (min-width: $breakpoint_l) {
    > div {
      max-width: 140px;

      &:first-child {
        max-width: 540px;
      }
    }
  }
}

.title {
  @include m-t3-bold;

  @media screen and (min-width: $breakpoint_m) {
    @include d-t3-bold;
  }
}

.bottomWidgets {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: $breakpoint_m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.extraInfo {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_ml) {
    flex-direction: row;
    align-items: center;
  }
}

.usefulLinks {
  color: $black_pearl;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_m) {
    flex-direction: row;
  }
}

.logoWrapper {
  display: flex;
  margin-bottom: 30px;

  @media screen and (min-width: $breakpoint_m) {
    margin-bottom: 0;
    margin-right: 30px;
  }
}

.logo {
  width: 74.9px;
  height: 60px;
  flex-shrink: 0;
}

.credentials {
  margin-bottom: 30px;

  @include d-t7-regular;

  @media screen and (min-width: $breakpoint_m) {
    margin-bottom: 0;
    margin-right: 30px;
  }
}

.menu {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (min-width: $breakpoint_m) {
    margin-top: 20px;
    margin-bottom: 0;
  }

  @media screen and (min-width: $breakpoint_ml) {
    margin-top: 0;
  }
}

.menuLink {
  margin-left: 0;

  @include d-t7-regular;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

.social {
  display: flex;
  align-items: center;

  @media screen and (min-width: $breakpoint_m) {
    margin-left: 30px;
  }
}

.socialLink {
  display: flex;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 24px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}
