@import 'styles/scss/_base';

.menu {
  position: fixed;
  right: 0;
  top: 0;
  background: $black_pearl;
  color: $white;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  z-index: 1;
  transform: translateX(100%);

  &.loaded {
    transition: 0.2s all ease-in-out;
  }

  &.open {
    transform: translateX(0%);
  }
}

.landrLogo {
  color: $white;
  width: 74.9px;
  height: 60px;
  flex-shrink: 0;
}

.title {
  @include d-t4-bold($white);

  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 326px;
  font-size: 20px;
}

.menuContent {
  padding: 32px;
  height: 100%;
  overflow: auto;
}

.menuItem {
  @include m-t2-bold($white);

  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  a {
    text-decoration: none;
  }

  &:hover,
  &.active {
    color: $hover_colour;
  }

  font-size: 28px;
}
