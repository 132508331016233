@mixin clearfix {
  zoom: 1;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin font_size($pixels) {
  font-size: $pixels;
  font-size: rem($pixels);
}

@mixin line_height($pixels) {
  line-height: $pixels;
  line-height: rem($pixels);
}

@mixin inline_overflow_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Desktop fonts
@mixin d-t1-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(80px);
  @include line_height(88px);
}

@mixin d-t2-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(40px);
  @include line_height(44px);
}

@mixin d-t2-regular($color: $black_pearl) {
  font-family: 'SharpGrotesk Book 21';
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(40px);
  @include line_height(44px);
}

@mixin d-t3-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(28px);
  @include line_height(30.8px);
}

@mixin d-t3-regular($color: $black_pearl) {
  font-family: 'SharpGrotesk Book 21';
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(28px);
  @include line_height(30.8px);
}

@mixin d-t4-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(20px);
  @include line_height(26px);
}

@mixin d-t4-regular($color: $black_pearl) {
  font-family: 'SharpGrotesk Book 21';
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(20px);
  @include line_height(26px);
}

@mixin d-t5-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 17';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(24px);
  @include line_height(28.8px);
}

@mixin d-t6-bold($color: $black_pearl) {
  font-family: $font_inter;
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(20px);
  @include line_height(30px);
}

@mixin d-t6-regular($color: $black_pearl) {
  font-family: $font_inter;
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(20px);
  @include line_height(30px);
}

@mixin d-t7-bold($color: $black_pearl) {
  font-family: $font_inter;
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(13px);
  @include line_height(19.5px);
}

@mixin d-t7-regular($color: $black_pearl) {
  font-family: $font_inter;
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(13px);
  @include line_height(19.5px);
}

@mixin d-t8-mono($color: $black_pearl) {
  font-family: 'Sohne Mono Buch';
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(14px);
  @include line_height(21px);
}

// Mobile fonts
@mixin m-t1-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(40px);
  @include line_height(44px);
}

@mixin m-t2-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(32px);
  @include line_height(35.2px);
}

@mixin m-t2-regular($color: $black_pearl) {
  font-family: 'SharpGrotesk Book 21';
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(32px);
  @include line_height(35.2px);
}

@mixin m-t3-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(24px);
  @include line_height(26.4px);
}

@mixin m-t3-regular($color: $black_pearl) {
  font-family: 'SharpGrotesk Book 21';
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(24px);
  @include line_height(26.4px);
}

@mixin m-t4-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(16px);
  @include line_height(20.8px);
}

@mixin m-t4-regular($color: $black_pearl) {
  font-family: 'SharpGrotesk Book 21';
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(16px);
  @include line_height(20.8px);
}

@mixin m-t5-bold($color: $black_pearl) {
  font-family: 'SharpGrotesk SemiBold 17';
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(20px);
  @include line_height(24px);
}

@mixin m-t6-bold($color: $black_pearl) {
  font-family: $font_inter;
  font-weight: $bold_font_weight;
  color: $color;

  @include font_size(16px);
  @include line_height(24px);
}

@mixin m-t6-regular($color: $black_pearl) {
  font-family: $font_inter;
  font-weight: $regular_font_weight;
  color: $color;

  @include font_size(16px);
  @include line_height(24px);
}

// Aspect ratio
@mixin one-to-one-aspect-ratio-box {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
}

@mixin sixteen-to-nine-aspect-ratio-box {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

@mixin aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin ie-only {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
