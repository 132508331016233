@import 'styles/scss/_base';

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  color: $white;

  @media screen and (min-width: $breakpoint_m) {
    display: none;
  }
}

.menu {
  position: fixed;
  left: 0;
  top: 0;
  background: $black_pearl;
  color: $white;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  z-index: 1;
  transform: translateX(-100%);
  padding: 32px;
  overflow-y: scroll;

  @media screen and (min-width: $breakpoint_m) {
    padding: 60px;
  }

  &.loaded {
    transition: 0.2s all ease-in-out;
  }

  &.open {
    transform: translateX(0%);
  }

  // TODO: fix overlay styles not being applied
  @media screen and (min-width: $breakpoint_xs) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 100vw;
      background: rgb(12 27 43 / 45%);
      transition: 0.2s all ease;
      opacity: 0;
      visibility: hidden;
    }

    &.open {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: $breakpoint_m) {
    width: 600px;
  }
}

.title {
  @include d-t4-bold($white);

  margin-bottom: 20px;
  max-width: 272px;

  @media screen and (min-width: $breakpoint_m) {
    @include d-t3-bold($white);
    margin-bottom: 32px;
    max-width: 440px;
  }
}

.language {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: $breakpoint_m) {
    display: none;
  }
}

.menuList {
  margin-bottom: 20px;
}

.menuItem {
  @include m-t2-bold($white);

  display: flex;

  a {
    text-decoration: none;
  }

  &:hover,
  &.active {
    color: $hover_colour;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: $breakpoint_xs) {
    font-size: 28px;
  }

  @media screen and (min-width: $breakpoint_m) {
    @include d-t2-bold($white);
  }
}

.copyrightWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.landrLogo {
  color: $white;
  width: 74.9px;
  height: 60px;
  flex-shrink: 0;
}

.searchIcon {
  color: $white;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.copyrightText {
  @include d-t7-bold($white);
}
