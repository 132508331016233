@import 'styles/scss/_base';

.link {
  margin-right: 12px;

  &:last-child {
    margin-right: 16px;
  }

  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $semi_bold_font_weight;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @include font_size(13px);
  @include line_height(18px);
}
