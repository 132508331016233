@import "styles/scss/_base";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 124px;
  margin: 0 auto;
  margin-bottom: -34px;
  z-index: 11;
}
