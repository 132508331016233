@import 'styles/scss/_base';

.closeButton {
  position: absolute;
  right: 15.5px;
  top: 15.5px;
  display: flex;

  @extend %button-reset;
}

.closeIcon {
  width: 59px;
  height: 59px;
  flex-shrink: 0;
  color: $white;
}
