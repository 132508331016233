@import 'styles/scss/_base';

.root {
  margin-bottom: 30px;

  a {
    text-decoration: none;
  }

  &.open {
    .menu {
      display: block;
    }

    .title {
      margin-bottom: 15px;
    }

    .arrowIcon {
      position: relative;
      top: 4px;
      transform: rotate(180deg);
    }
  }

  @media screen and (min-width: $breakpoint_m) {
    margin-bottom: 0;
  }
}

.title {
  display: flex;
  justify-content: space-between;

  @include m-t6-bold;

  @media screen and (min-width: $breakpoint_m) {
    // Background and foreground colors do not have a sufficient contrast ratio.
    @include d-t7-bold($storm_grey);

    margin-bottom: 15px;
  }
}

.arrowIcon {
  width: 13px;
  height: 13px;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint_m) {
    display: none;
  }
}

.menu {
  display: none;

  @media screen and (min-width: $breakpoint_m) {
    display: block;
  }
}

.link {
  @include d-t7-bold;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
