// Core colours
$black_pearl: #0c1b2b;
$grey: #d7d6d2;
$white: #ffffff;

// Extra colours
$land_blue: #0c1b2a;

// Accent colours
$clementine: #fdaf00;
$acid_green: #e0f416;
$teal: #44caca;
$moss: #777d5b;
$khaki: #928163;
$purple: #8445d4;
$lavender: #d7c4f6;
$storm_grey: #9e9ea9;
$citrus_yellow: #f9de00;
$butter_yellow: #f0eab1;
$baby_blue: #b6eefe;
$ocean_blue: #007cb3;
$electric_blue: #496aed;
$blue_grey: #607f97;

$core_colours: (
  'black-pearl': $black_pearl,
  'grey': $grey,
  'white': $white,
);

$accent_colours: (
  'clementine': $clementine,
  'acid-green': $acid_green,
  'teal': $teal,
  'moss': $moss,
  'khaki': $khaki,
  'purple': $purple,
  'lavender': $lavender,
  'storm-grey': $storm_grey,
  'citrus-yellow': $citrus_yellow,
  'butter-yellow': $butter_yellow,
  'baby-blue': $baby_blue,
  'ocean-blue': $ocean_blue,
  'electric-blue': $electric_blue,
  'blue-grey': $blue_grey,
);

// Accent colour
$accent_colour: var(--accent-color, $purple);

// Hover colour
$hover_colour: $blue_grey;

// Font
$font_inter: var(--font-inter), sans-serif;

// Font sizes
$regular_font_weight: 400;
$medium_font_weight: 500;
$semi_bold_font_weight: 600;
$bold_font_weight: 700;
$extra_bold_font_weight: 800;
$black_bold_font_weight: 900;

// Breakpoints
$breakpoint_2xs: 320px;
$breakpoint_xs: 480px;
$breakpoint_s: 512px;
$breakpoint_m: 768px;
$breakpoint_ml: 991px;
$breakpoint_l: 1024px;
$breakpoint_xl: 1280px;
$breakpoint_xxl: 1440px;
