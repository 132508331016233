@import 'styles/scss/_base';

.trigger {
  appearance: none;
  background-color: transparent;
  border: none;

  display: flex;
  align-items: center;
  gap: 4px;

  font-family: 'SharpGrotesk SemiBold 21';
  font-weight: $semi_bold_font_weight;
  text-decoration: none;
  color: #d7d6d2;

  @include font_size(13px);
  @include line_height(24px);
}

.modalContent.modalContent:first-child {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.modalContent.modalContent:last-child {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.heading.heading {
  color: #142739;
  font-family: 'SharpGrotesk SemiBold 21';
  @include font_size(28px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.description.description {
  color: #142739;
  font-family: 'SharpGrotesk Book 21';
  @include font_size(16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button.button {
  font-family: 'SharpGrotesk SemiBold 21';
  @include font_size(16px);
  @include line_height(18px);
  font-style: normal;
  font-weight: 600;
}
