@import 'styles/scss/_base';

.login {
  font-family: 'SharpGrotesk Semibold 21';
  font-weight: $bold_font_weight;
  background-color: $white;
  border-radius: 100px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;

  &.size- {
    &sm {
      height: 24px;
      font-size: 13px;
      line-height: 18px;
      padding: 4px 8px;
    }

    &lg {
      height: 48px;
      font-size: 20px;
      line-height: 24px;
      padding: 12px 16px;
    }
  }
}
